import React from "react";
import i18next from "i18next";
import StandardPage from "../components/StandardPage";
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Home = () => {

    const { t, i18n } = useTranslation(); // Get translation function and instance

    return (
        <StandardPage
            //title={t("app_title")}
            lang={i18next.language}
            style={{ align: 'center' }}
        //onLanguageChange={handleLangChange}
        >
            <Typography variant="h1" align="center">
                Information Assistant <br /> for Worker's Rights in Germany
            </Typography>
            <Typography variant="h2" align="center">
                AI to fight injustice on the workplace
            </Typography>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    minWidth: '100%',
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center", // Center vertically
                }}>
                {/* left box */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "20vh", // Adjust height as needed
                        minWidth: "40vw",
                        border: "1px solid gray"
                    }}
                >
                    <Button variant="customWide" size='large' color="inherit" component={Link} to="/app">Get Help on Dismissal</Button>
                </Box>
                {/* Right Box */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "20vh", // Adjust height as needed
                        minWidth: "40vw",
                        border: "1px solid gray"

                    }}
                >
                    <Button variant="customWide" size='large' color="inherit" component={Link} to="/chat">Ask your question</Button>
                </Box>



            </Stack>
        </StandardPage >
    );
};

export default Home;
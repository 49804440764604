import { createTheme } from "@mui/material";

const Theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2", // Custom primary color
        },
        secondary: {
            main: "#151515", // Custom secondary color
            hover: "#15151599"
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "customWide" },
                    style: ({ theme }) => ({
                        width: "40%", // Custom width for this variant
                        maxWidth: "40%", // Custom width for this variant
                        textTransform: "none", // Disable uppercase
                        padding: "8px 16px", // Default padding
                        borderRadius: "8px", // Rounded corners
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff", // White text
                        "&:hover": {
                            backgroundColor: theme.palette.secondary.hover, // Darker green on hover
                        },
                    }),
                },
            ],
            defaultProps: {
                size: "medium", // Default button size
                variant: "outlined", // Default button variant
            },
        },
    },
    typography: {
        fontFamily: "'Manrope', sans-serif",
        fontWeightLight: 100, // Set font-weight for "light" text
        fontSize: 14, // Base font size (affects body1, body2 by default)
        h1: {
            fontSize: "4rem", // 48px
            fontWeight: 100,
            textAlign: 'center',
            paddingBottom: "4rem"
        },
        h2: {
            fontSize: "2.5rem", // 40px
            fontWeight: 300,
            textAlign: 'center',
            paddingBottom: "3rem"
        },
        h3: {
            fontSize: "1.5rem", // 32px
            fontWeight: 400,
            textAlign: 'center',
            paddingBottom: "2rem"
        },
        body1: {
            fontSize: "1.2rem", // 16px
        },
        body2: {
            fontSize: "0.875rem", // 14px
        },
        body3: {
            fontSize: "0.875rem", // 14px
        },
        caption: {
            fontSize: "0.75rem", // 12px
        },
    },
});

export default Theme;
import React from "react";
import i18next from "i18next";
import StandardPage from "../components/StandardPage";
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const Chat = () => {
    const { t, i18n } = useTranslation(); // Get translation function and instance

    return (
        <StandardPage
            title={t("contact_title")}
            lang={i18next.language}
        //onLanguageChange={handleLangChange}
        >
            <Typography variant='h2' align="center">
                {t("chat_title")}
            </ Typography>
            <Typography variant="body1" align="center">
                {t("chat_body")}
            </Typography>
        </StandardPage >
    );

};

export default Chat;
import React from "react";
import i18next from "i18next";
import StandardPage from "../components/StandardPage";
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const About = () => {
    const { t, i18n } = useTranslation(); // Get translation function and instance

    return (
        <StandardPage
            title={t("about_title")}
            lang={i18next.language}
        //onLanguageChange={handleLangChange}
        >
            <Typography variant='h2' align="center">
                {t("app_title")}
            </ Typography>
            <Typography variant="body1" align="center">
                Welcome to the Home Page!
            </Typography>
        </StandardPage >
    );

};

export default About;
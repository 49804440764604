import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './components/Router.js';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
// theme
import Theme from './theme.js';
// Translations
import i18n from "i18next";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import trEN from './locales/en/translations.json'
import trRU from './locales/ru/translations.json'

//tmeme
import { ThemeProvider } from "@mui/material";


i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});


const resources = {
  en: {
    translation: trEN,
  },
  ru: {
    translation: trRU,
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <I18nextProvider i18n={i18next}>
        <AppRouter />
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import React from "react";
import { CssBaseline, Box, Container, Typography } from "@mui/material";
import AppMenu from "./menu";

const StandardPage = ({ title, children, lang, onLanguageChange }) => {
    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: "100vh",
                    backgroundImage: "url('/background.svg')", // Use provided background or default
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "rgb(243, 234, 200)",
                }}
            >
                <Container
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                        backgroundColor: "white",
                        borderRadius: 2,
                        boxShadow: 3,
                        paddingTop: 6,
                        paddingLeft: 4,
                        paddingRight: 4,
                        maxWidth: "lg",
                        minWidth: "90vw",
                        minHeight: "150vh",
                    }}
                    disableGutters
                >
                    {/* App Menu */}
                    <AppMenu onLanguageChange={onLanguageChange} />

                    {/* Title
                    {title && (
                        <Typography variant="h4" align="center" gutterBottom>
                            <h2>{title}</h2>
                        </Typography>
                    )} */}

                    {/* Main Content Area */}
                    {children}
                </Container>
            </Box>
        </>
    );
};

export default StandardPage;
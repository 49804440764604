// src/api/api.js
import { configSettings } from '../config/settings';

const BASE_URL = configSettings.app.BACKEND_BASE_URL; // Ensure this matches your backend URL

export const structureJson = (input_data) => {
    return Object.entries(input_data).map(([key, value]) => ({
        i_element_id: key,
        i_value: value
    }));
}

export const fetchNode = async (sessionId, nodeId, input_data) => {
    const data_to_send = structureJson(input_data)
    console.log(data_to_send)
    const response = await fetch(`${BASE_URL}/submit/${sessionId}/${nodeId}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data_to_send)
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    // todo: chek response session ID is the same as in the outgoing message
    return await response.json();
};

export const postUpdateLang = async (sessionId, nodeId, langMessage) => {
    console.log(`Sending ${JSON.stringify(langMessage)}`);

    const response = await fetch(
        `${BASE_URL}/lang/${sessionId}/${nodeId}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(langMessage)
    });
    if (!response.ok) {
        throw new Error(`Failed to update language on the backend: ${response.statusText}`);
    }
    // todo: chek response session ID is the same as in the outgoing message
    return await response.json();
};


export async function getRoot() {
    console.log('Backend URL from env: ' + BASE_URL)
    const response = await fetch(`${BASE_URL}/start`);
    if (!response.ok) {
        throw new Error(`Error fetching node ROOT: ${response.statusText}`);
    }
    return response.json();
}

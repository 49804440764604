import React from "react";
import i18next from 'i18next';
import { MenuItem, Select } from "@mui/material";


const LanguageSwitcher = ({ onLanguageChange }) => {

    const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        i18next.changeLanguage(newLang);
        if (onLanguageChange) {
            onLanguageChange(newLang); // Close the menu
        }
    };

    return (
        <Select
            value={i18next.language}
            onChange={handleLanguageChange}
            displayEmpty
            sx={{
                minWidth: 60,
                "& .MuiSelect-icon": {
                    display: "none", // Hide default arrow
                },
            }}
        >
            <MenuItem value="en">
                <img
                    src="https://flagcdn.com/w40/us.png" // Flag for English
                    alt="English"
                    style={{ width: 20, marginRight: 8 }}
                />
                English
            </MenuItem>
            <MenuItem value="ru">
                <img
                    src="https://flagcdn.com/w40/ru.png" // Flag for Russian
                    alt="Russian"
                    style={{ width: 20, marginRight: 8 }}
                />
                Russian
            </MenuItem>
        </Select>
    );
};

export default LanguageSwitcher;

import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, Typography, IconButton, Button, Menu, MenuItem } from '@mui/material';
import LanguageSwitcher from './language_switcher';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const AppMenu = ({ onLanguageChange }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile screens (smaller than 600px)

    const [anchorEl, setAnchorEl] = useState(null);
    const [lang, setLang] = useState(null);


    // Open and close burger menu
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLangChange = (new_lang) => {
        console.log(`Setting new MENU language: ${new_lang}`)
        setLang(new_lang);
        handleMenuClose();
        if (onLanguageChange) {
            onLanguageChange(new_lang); // Close the menu
        }
    }

    const handleLogoClick = () => {
        // Redirect to third-party page in a new tab
        window.open("https://isi-ai.de", "_blank");
    };

    return (
        <>
            {/* Top Navigation Bar */}
            <AppBar
                position="static"
                sx={{

                    backgroundColor: "white", // White background
                    boxShadow: "none", // No shadow
                    color: "black", // Black text
                    //backgroundColor: "rgba(0,0,0,0.7)",
                    marginBottom: 16,
                }}

            >
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "0.5px solid #A9A9A9", // Dark gray bottom border
                    }}
                    disableGutters
                >

                    {/* Logo Button */}
                    <Button
                        onClick={handleLogoClick}
                        variant="text"
                        sx={{
                            //display: "flex",
                            //alignItems: "center",
                            //textTransform: "none",
                            //color: "inherit",
                            marginRight: 4,
                            //marginLeft: 0,
                            paddingLeft: 0,
                            //paddingRight: 0,
                        }}
                    >
                        <img
                            src="/logo.png" // Replace with your logo URL
                            alt="Logo"
                            style={{ height: 48 }}
                        />
                    </Button>

                    {/* App Name */}
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Your App Name
                    </Typography>

                    {/* Conditional Rendering: Show Burger Menu on Mobile */}
                    {isMobile ? (
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleMenuClose} component={Link} to="/">Home</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/about">About</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={Link} to="/contact">Contact</MenuItem>
                                <LanguageSwitcher onLanguageChange={handleLangChange} />
                            </Menu>
                        </>
                    ) : (
                        // Show Buttons on Wider Screens
                        <>
                            <Button variant="text" color="inherit" component={Link} to="/">Home</Button>
                            <Button variant="text" color="inherit" component={Link} to="/about">About</Button>
                            <Button variant="text" color="inherit" component={Link} to="/contact">Contact</Button>
                            <LanguageSwitcher onLanguageChange={handleLangChange} />
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>

    );

};

export default AppMenu;
import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import DecisionTree from '../components/DecisionTree';
import i18next from "i18next";
import StandardPage from "../components/StandardPage";
import { useTranslation } from "react-i18next";


function App() {

  const { t, i18n } = useTranslation(); // Get translation function and instance

  // State for controlling the burger menu
  const [lang, setLang] = useState(null);

  return (
    <StandardPage
      title={t("app_title")}
      lang={i18next.language}
    >

      {/* Centering Container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically (if needed)
          width: "100%", // Full width of the parent container
          mt: 4, // Add margin-top (optional for spacing)
        }}
      >
        <DecisionTree lang={lang} />
      </Box>
    </StandardPage >

  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { fetchNode, getRoot, postUpdateLang } from '../api/api'; // Ensure this matches the correct path
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const DecisionTree = () => {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language); // Initialize with the current language
    const [session_id, setSessionId] = useState(null);
    const [node_id, setNodeId] = useState(null);
    const [node, setNode] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [error, setError] = useState(null);
    const [history, setHistory] = useState([]);

    const loadRoot = async () => {
        try {
            const data = await getRoot();
            setSessionId(data.session_id);
            setNodeId(data.node_id);
            setNode(data.render_element);
            setHistory([]); // Clear history when going back to the start
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message);
        }
    };

    const updateLanguage = async (session_id, node_id, language) => {
        if (!session_id || !node_id) {
            console.warn("No session ID or no Node ID set. Cannot push language to the backend;");
            return;
        }
        console.log(`Setting current language of the DecisionTree to ${language}`)
        setCurrentLanguage(language);
        try {
            const langMessage = {
                lang: language
            };
            const new_node = await postUpdateLang(session_id, node_id, langMessage)
            setNode(new_node.render_element);
            // todo: convert to checks
            //setNodeId(new_node.node_id);
            //setSessionId(new_node.session_id);
            setHistory([]); // Clear history when going back to the start
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message);
        }
    }

    useEffect(() => {
        console.log("DecisionTree component mounted.");
        loadRoot(); // Call once on mount
    }, []); // Runs only on initial render (mount)

    useEffect(() => {
        console.log("Language changed, DecisionTree updated.");
        updateLanguage(session_id, node_id, i18n.language);
    }, [i18n.language]); // Runs on every update of language

    const handleInputChange = (id, value) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [id]: value,
        }));
    };

    const handleOptionClick = async (btn_id) => {
        try {
            // Collect all input values to send to the backend
            var input_data = {
                ...inputValues,

            };
            input_data[btn_id] = true
            //For the back button, puts the current node into history stack
            setHistory((prevHistory) => [...prevHistory, node]);
            // Send input_data to backend here if needed
            const data = await fetchNode(session_id, node_id, input_data);
            console.log(input_data)
            console.log("Sending input data to backend:", input_data);
            // Send input_data with request
            setNode(data.render_element);
            setNodeId(data.node_id);
            setInputValues((prevValues) => ({}));
        } catch (err) {
            console.error('Fetch error:', err);
            setError(err.message);
        }
    };

    const handleBackClick = () => {
        if (history.length > 0) {
            // Retrieve the last node from history and update the current node
            const previousNode = history[history.length - 1];
            setHistory((prevHistory) => prevHistory.slice(0, -1)); // Remove the last node from history
            setNode(previousNode);
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const renderElement = (element) => {
        switch (element.i_element_type) {
            case 'button':
                return (
                    <Button variant="customWide"
                        size='large'
                        key={element.i_element_id}
                        id={element.i_element_id}
                        onClick={() => handleOptionClick(element.i_element_id)}
                    >
                        {element.i_element_text}
                    </Button>
                );
            case 'text':
                return (
                    <Typography
                        variant="body1"
                        key={element.i_element_id} id={element.i_element_id}
                    >
                        {element.i_element_text}
                    </Typography>

                );
            case 'input_text':
                return (
                    <TextField
                        key={element.i_element_id}
                        id={element.i_element_id}
                        label={element.i_element_text || ""}
                        value={inputValues[element.i_element_id] || ""}
                        onChange={(e) => handleInputChange(element.i_element_id, e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                );
            case 'input_number':
                return (
                    <TextField
                        key={element.i_element_id}
                        id={element.i_element_id}
                        label={element.i_element_text || ""}
                        type="number"
                        value={inputValues[element.i_element_id] || ""}
                        onChange={(e) => handleInputChange(element.i_element_id, e.target.value)}
                        variant="outlined"
                    />
                );
            case 'input_date':
                return (
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns} key={element.i_element_id}>
                            <DatePicker
                                label={element.i_element_text || "Select Date"}
                                value={inputValues[element.i_element_id] || null}
                                onChange={(newValue) => handleInputChange(element.i_element_id, newValue)}
                            />
                        </LocalizationProvider>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {node ? (
                <>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            minWidth: '100%',
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h3">
                            {node.r_element_header}
                        </Typography>
                        <Typography style={{ textAlign: 'center', }} variant='body1'>
                            {node.r_element_description}
                        </Typography>

                        {/* Render all elements received from the backend */}
                        {node.input_elements.map((element) => renderElement(element))}
                    </Stack>

                    {/* <Button variant="outlined" onClick={handleBackClick} disabled={history.length === 0}>
                        Back
                    </Button>
                    <Button variant="outlined" onClick={loadRoot} style={{ marginLeft: '10px' }}>
                        Back to Start
                    </Button> */}
                </>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );
};

export default DecisionTree;
